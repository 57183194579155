
.productsContainer{
    width: 100%;
}
.headingH1{
    margin: 0 auto;
    text-align: center;
    font: 600 2vmax 'Roboto';
    color: var(--textC1);
}

.productsRow{
    width: 100%;
    padding: 4vmax 5vmax;
    display: flex;
    justify-content: center;
    /* align-items: center; */
    gap: 1rem;
}
.products-row{
    text-align: center;
    display: flex;
    gap: 2rem;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    /* padding: 4vmax 0; */
}
.productsDetailBox > h1{
    width: 100%;
    text-align: center;
    font: 600 2vmax 'Roboto';
    padding: 0.6vmax 0;
    color: var(--bg1) !important;
    margin-bottom: 2.5vmax;
}

.sidebarbox{
    width: 25%;
    /* border: 1px solid gray; */
}
/*  */

.productsDetailBox{
    width: 100% ;
    /* border: 1px solid gray; */
    /* background-color: aqua; */
    /* display: flex;
    flex-direction: column;
    gap: 1rem; */
    width: 100%;
    /* padding: 3vmax 1vmax; */
    background-color: rgb(250, 255, 251);
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 0.1rem;
    row-gap: 1rem;
}
.alphaNum{
   width: 100%;
    text-align: center;
    font: 300 3vmax 'Roboto';
    color: #008512;
    border-bottom: 1px solid rgb(213, 213, 213);
}
.p-box{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 0.1rem;
    row-gap: 1rem;
}
.productItem2{
    width: 30%;
    height: auto;
    align-items: center;
    text-align: center;
    text-decoration: none;
    color: #008512;
    text-transform: uppercase;
}
.productItem2:hover{
    text-decoration: underline;
    transform: scale(1.05);
    transition: all 0.5s;

    

}
.paginationBox{
    width: 100%;
    display: flex;
    justify-content: center;
    text-align: center;
}

.pagination{
    /* width: 100%; */
    display: flex;
    /* gap: 1rem; */


}
.pagination  > li:first-child{
    border-radius: 0.4rem 0 0 0.4rem !important;
}
.pagination  > li:last-child{
    border-radius: 0 0.4rem   0.4rem  0 !important;
}
.pagination > li{

    list-style: none;
    padding: 0.5vmax 1.6vmax;
    border: 1px solid rgb(255, 255, 255);
    background-color: var(--bg3);
}

.pagination > li > a{
    text-decoration: none;
    color: var(--textC2);
    cursor: pointer;
}

.pageItemActive{
    background-color: var(--textC1) !important;
    color: white !important;
}
.noProduct{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.noProduct > img{
    width: 50%;
}
.noProduct > h2{
    font: 600 1.8vmax 'Roboto';
    color: gray;
}
.productImg{
    width: 60%;

}
@media screen  and (max-width:600px){
    .productsRow{
    width: 100%;
    padding: 4vmax 0.2vmax;
    padding-bottom: 6vmax;
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* align-items: center; */
    gap: 1rem;
}
.headingH1{
    margin: 0 auto;
    text-align: center;
    font: 600 3vmax 'Roboto';
    color: var(--bg1);
}
.productsDetailBox > h1{
    width: 100%;
    text-align: center;
    font: 600 2.5vmax 'Roboto';
    padding: 0.6vmax 0;
}
.sidebarbox{
    width: 100%;
    /* border: 1px solid gray; */
}

.productsDetailBox{
    width: 100% ;}

    .products-row{
        text-align: center;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        padding: 4vmax 0;
    }
    .alphaNum{
        width: 100%;
         text-align: center;
         font: 300 4.5vmax 'Roboto';
         color: #008512;
         border-bottom: 1px solid rgb(213, 213, 213);
     }

    .productItem2{
        width: 45%;
        height: auto;
        align-items: center;
        text-align: center;
        text-decoration: none;
        color: #008512;
       text-transform: uppercase;
       font-size: small;
    }
    .productItem2 > img{
        width: 100%;
    }
}