.modalContainer{
    background-color: ghostwhite;
    /* height: 80vh; */
    position: absolute;
}


 .box2{
    width: 100%;
    min-width: 30vw;
    display: flex;
    flex-direction: column;
    padding: 2vmax;
}
 .box2 > h2{
    color: rgb(77, 77, 77);
    font: 900 1.4vmax 'Roboto';
    font-family: 'Roboto';
    margin: 1vmax 0;
    text-align: center;
}
 .box2 > form{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 1rem;
    padding-top: 1vmax;

   
}
/*  .box2 .inpStyle{ */
 .box2 > form > textarea,
 .box2 > form > select,
     .box2 > form > input{ 
        height: 3vmax;
        padding: 0.8vmax;
        font: 600 1vmax 'Roboto';
        color: var(--textC1);
        outline: none;
        border-radius: 0.2rem;
        border: none;
        background-color: white;
        border: 1px solid gray;
        max-height: 16vh;


}
.doblable{
    font: 600 0.9vmax 'Roboto';
    color: var(--textC1);
}
.dob{
    width: 100%;
    padding: 0.8vmax;
    font: 600 1vmax 'Roboto';
    color: var(--textC1);
    outline: none;
    border-radius: 0.2rem;
    border: none;
    background: #fff url(https://cdn1.iconfinder.com/data/icons/cc_mono_icon_set/blacks/16x16/calendar_2.png) 97% 50% no-repeat;
   
  }
  .dob::placeholder{
    font-size  : 17px;
     font-style : normal;
     font-weight: 400;
  }
  [type="date"] {
  }
  
  .dob::-webkit-inner-spin-button {
    display: none;
  }

.ratingBox{
    display: flex;
    /* flex-direction: column; */
    justify-content: space-between;
    align-items: center;
    padding: 1vmax;
    border: 2px solid white;
    background-color: white;
    border-radius: 0.2rem;
    color: rgb(39, 39, 39);
    font: 600 1vmax 'Roboto';



}
.actionBox{
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 2rem;
}
.FormSectionBtn{
    width: 25%;
    background-color: var(--bg1) !important;
    color: white !important;
    transition: all 0.5s;
    cursor: pointer;
    padding: 0.8vmax;
    border: none;
    border-radius: 2rem;
    font: 600 1vmax 'Roboto';
}
.close{
    width: 25%;
    /* background-color: var(--textC1) !important; */
    color: rgb(98, 98, 98) !important;
    transition: all 0.5s;
    cursor: pointer;
    padding: 0.8vmax;
    border: none;
    border-radius: 2rem;
    font: 600 1vmax 'Roboto';
}
.FormSectionBtn:hover{
    background-color: var(--bg2) !important;
}
@media screen and (max-width:650px){


 .box2{
     /* width: 100%; */
     width: 75vw;
    padding: 0.2vmax;
}
 .box2 > h2{
    /* color: rgb(46, 45, 45); */
    font: 900 2.5vmax 'Roboto';
    color: var(--bg1);
}
 .box2 > form > textarea,

 .box2 > form > select,
     .box2 > form > input{
        height: 5.5vmax;
        font: 600 1.9vmax 'Roboto';
     
    }
    .doblable{
        font: 600 1.8vmax 'Roboto';
        color: var(--textC1);
    }

.actionBox{
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 1rem;
}
.FormSectionBtn{
    width: 35%;
  
    transition: all 0.5s;
    cursor: pointer;
    padding: 1vmax;
    border: none;
    font: 600 1.8vmax 'Roboto';
}
.close{
    width: 35%;

    transition: all 0.5s;
    cursor: pointer;
    padding: 1vmax;
    border: none;
    font: 600 1.8vmax 'Roboto';
}


}