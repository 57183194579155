.accContainer{
    width: 100%;
    /* padding: 5vmax 0vmax; */
    padding-top: 5.5vmax;
    /* background-color: var(--bg2); */
    border-radius: 0.2rem;
    overflow: hidden;
}
.accContainer > h3{
    padding: 1vmax;
    font: 400 1.1vmax 'Roboto';
    color: var(--textC2);
    /* border: 1px solid rgb(223, 223, 223); */
    /* border-bottom: none; */
    background-color: var(--bg3);
    border-radius: 0.4rem 0.4rem 0 0;
    overflow: hidden;
}
.accordion__heading{

    /* border-bottom: 1px solid var(--textC2) !important; */

}
.accordion__heading:first-child{
    /* border-top: 1px solid var(--textC2) !important; */

}
.accordion__heading > div{
    background-color: var(--bg2) !important;
    color: var(--textC1);
    font-family: 'Roboto';

}
.accordion__panel{
    /* background-color: aqua; */
    color: var(--textC1);
    font-family: 'Roboto';
}
.categoryBox1{
    width: 100%;
    padding: 0 0vmax;
    display: flex;
    flex-direction: column;
    gap: 0.4rem;
    /* transition: all 1s; */
}
.categoryBox1 > p{
    /* background-color: rgb(231, 231, 231); */
    border-bottom: 1px solid rgb(232, 231, 231);
    color: var(--textC1);
    font: 500 1.1vmax 'Roboto';
    padding: 0.5vmax;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 1rem;
}
.categoryBox1 > p:hover{
    cursor: pointer;
    transition: all 0.8s;
    padding-left: 1vmax;
    color: var(--bg1);

}

@media screen  and (max-width:600px){
    .accContainer{
        width: 100%;
        padding: 3vmax 0;}

    .accContainer > h3{
        padding: 1vmax;
        font: 600 2vmax 'Roboto';
        color: var(--textC1);
    }
    .categoryBox1 > li{
        color: var(--textC1);
        font: 500 2vmax 'Roboto';
    }
    
}