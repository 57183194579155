/* Base styles */
.not-found-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    font-family: Arial, sans-serif;
  }
  
  .not-found-title {
    font-size: 8rem;
    color: green;
    margin-bottom: 1rem;
  }
  
  .not-found-text {
    font-size: 1.5rem;
    text-align: center;
    margin-bottom: 1rem;
  }
  
  .not-found-link {
    background-color: green;
    color: #ffffff;
    padding: 0.8rem 1.5rem;
    border-radius: 0.3rem;
    text-decoration: none;
    transition: background-color 0.3s ease;
  }
  
  .not-found-link:hover {
    background-color: #54d99d;
  }
  
  /* Responsive styles */
  @media (max-width: 768px) {
    .not-found-title {
      font-size: 6rem;
    }
  
    .not-found-text {
      font-size: 1.2rem;
    }
  }
  
  @media (max-width: 480px) {
    .not-found-title {
      font-size: 4rem;
    }
  
    .not-found-text {
      font-size: 1rem;
    }
  
    .not-found-link {
      padding: 0.6rem 1.2rem;
    }
  }
  