.footerContainer{
    max-width: 100%;
    width: 100vw;
    /* height: 50vh; */
    /* padding: 3rem 7rem; */
    padding-top: 1vmax;
    /* background: linear-gradient(black); */
    background-color: rgb(27, 27, 27);
    /* margin-top: 1vmax; */
    bottom: 0;
    color: var(--textC2);
    /* border-top: 10px solid var(--bg1); */
}
.topBox{
    /* padding-top: -2rem; */
    height: 44vh;
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* flex-wrap: wrap ; */
    padding: 2rem  10vmax;
    gap: 2rem;

}
.topBox > div{
    width: 25%;
    height: auto;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;


}
.downBox{
    /* height: 5vh; */
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2rem  10vmax;
    background-color: var(--bg1) !important;
}
.downLeftBox{
    /* border: 1px solid gray; */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 0.1rem;

}

.downLeftBox>p{
    color: var(--textC2);
    font-family:'Times New Roman', Times, serif;
    font-size: 1rem;
}
.downLeftBox > p {
    color: var(--textC2);
}

.downRightBox{
    display: flex;
    align-items: center;
    gap: 0.5rem;
}
.downRightBox > a {
    text-decoration: none;
    color: white;
}

.downRightBox > a > svg{
    color: var(--textC2);
    transition: 0.5s ease-in; 
    font-size: 1.6vmax;
    margin: 0  0.4rem;

} 
.downRightBox > a > svg:hover{
    /* background-color: white; */
    color: rgb(102, 100, 100);
    transform: scale(1.2);

}

.box1{
    padding-top: 1vmax;
    max-width: 20%;
    height: 100%;

}
.box1> div> p{
    /* font-family: "Sansita"; */
    line-height: 36px;

}
.box2{
    max-width: 20%;
    height: 100%;   

    display: flex;
    flex-direction: column;
    /* justify-content: space-between; */
    gap: 0.8rem;
}
.topBox .box3{
    width: 40%;
    /* height: 100%; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* align-items: center; */
    /* padding-top: 1rem; */
    gap: 0.6rem;
    /* background-color: antiquewhite; */

}
.Box2heading,
.Box1heading{
    width: 90%;
    padding: 0.8rem 0rem;
    /* background-color: var(--bg2); */
    font: 600 1.1rem "Roboto" ;
    translate: unset;
    color: white;
    margin-bottom: 1rem;
    border-bottom: 1px solid var(--bg1);    
    /* height: auto; */
    text-transform: uppercase;
}

.atag{
    /* width: 40%; */
    text-decoration: none;
    color: var(--textC2);
    font: 500 1rem "Sansita";
    transition: 0.5s ease-in-out;
    /* padding: 0.1rem 0.2rem; */
    /* margin: 0.2rem 0; */
    /* border-bottom: 1px solid rgb(65, 65, 65); */

    display: flex;
    /* justify-content: center; */
    align-items:center ;

}
.atag >svg{
    font-size: 1.5vmax;
    color: var(--bg1);
}
.atag:hover{
    color: var(--hoverText);
    padding-left: 0.6rem;
}
.colorMidDark{
    color: var(--bg1);
}



.box3 .mapBox{
    width: 100%;
    height: 14vmax;
    padding: 0.5rem;
    border: 2px solid gray;
    
}
.box3 .logoBox{
    /* padding: 1rem; */
    text-align: center;
    width: 100%;
    
}
.box3 .logoBox > img{
    height: 80px;
    /* mix-blend-mode:lighten; */
}
.box3> .logoBox> h1{
    color: var(--bg3);
    font: 600 2vmax 'Roboto';

}
.box3> .logoBox> p{
    font :600 1rem "Roboto";
    color: var(--textC2);
    /* margin-bottom: 0.5vmax; */
}
.joinBtn{
    border: 2px solid var(--textC2);
    padding: 0.5vmax 0.8vmax;
    border-radius: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font: 500 1vmax 'Roboto';
    transition: all 0.5s;
    color: var(--textC2);
}
.joinBtn:hover{
    color: var(--hoverText);
}

/* subscribeBox */
.subscribeBox{
    width: 80%;

    margin: 0 auto;
    margin-top: 1vmax;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.5vmax 2.5vmax;
    /* background-color: white; */
    border: 2px solid white;
    /* color: black; */
    /* background-image: url('https://undsgn.com/wp-content/uploads/2018/04/ltotbngnzzu.jpg'); */
    background-position: center;
    border-radius: 0.5rem;
}
.subscribeBox > h2{
    font: 600 2vmax 'Roboto';
    color: var(--bg1);
}
.subscribeBox >div{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
}
.subscribeInp{
    border: 1px solid var(--bg1);
    background-color: var(--bg3) !important;
    padding: 0.8vmax;
    border-radius: 0.5rem;

}
.subscribeInp >input{
    font: 300 1.2vmax 'Roboto';
    border: none;
    outline: none;
    background-color: transparent;
    color: var(--textC2);
}
.subscribeInp >input::placeholder{
    color: var(--textC2);

}
.subscribeInp > svg{
    color: white;
    font-size: 1.5vmax;
}
.subscribeInp > svg:hover{
    transition: all 0.5s;
    transform: rotate(-360deg);
    cursor: pointer;
}
.contactBoxs > span > p{
    font: 300 0.9vmax 'Roboto';
    color: var(--textC2);

}
.ptag{
    display: flex;
    align-items: center;
    gap: 1rem;
    
}

.ptag1 > a{

    color: var(--textC1) !important;
    text-decoration: none;
    gap: 1rem;
    
}
.ptag > svg{
    font-size: 25px !important;
}
.ptag > a{
    display: flex;
    align-items: center;
    padding-top: 0.2rem;
    gap:0.5rem;
    color: white;
    text-decoration: none;
}
.contactBoxs > svg{
    color: var(--bg1);
    font-size: 2vmax;

}
/* subscribeBox end */

@media screen and (max-width:952px){
    .footerContainer{
        max-width: 100%;
        width: 100vw;
        /* height: 50vh; */
        /* padding: 2rem 0rem; */
        /* margin-top: rem; */
        bottom: 0;
        color: var(--textC2);
    }

   .topBox{
    min-width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    padding: 2rem  1rem;
    /* border: 1px solid gray; */

}

.topBox > div{
    width: 100%;
}

.topBox .box3{
    width: 100%;
    /* height: 100%; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* padding-top: 1rem; */
    gap: 0.6rem;
    /* background-color: antiquewhite; */

}


.downRightBox > a > svg{
    color: white;
    transition: 0.5s ease-in; 
    font-size: 2.6vmax;
    margin: 0  0.4rem;

} 
.joinBtn{
    padding: 0.6vmax 0.9vmax;
    border-radius: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font: 500 1.6vmax 'Roboto';
    transition: all 0.5s;
}
/* subscribeBox */
.subscribeBox{
    width: 90%;

    margin: 0 auto;
    margin-top: 1vmax;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    justify-content: center;
    align-items: center;
    padding: 2.5vmax 1vmax;
    /* background-color: white; */
    border-radius: 0.5rem;
}
.subscribeBox > h2{
    font: 600 3vmax 'Roboto';
    color: var(--bg1);
}
.subscribeInp{
    width: 80%;
    border: 1px solid var(--bg1);
    background-color: var(--bg3) !important;
    padding: 1.4vmax;
    border-radius: 0.5rem;

}
.subscribeInp >input{
    width: 80%;
    font: 300 2vmax 'Roboto';
    border: none;
    outline: none;
    background-color: transparent;
    color: var(--textC2);
}
.subscribeInp > svg{
    color: white;
    font-size: 2.5vmax;
}
.contactBoxs > span > p{
    font: 300 1.6vmax 'Roboto';
    color: var(--textC2);

}
.contactBoxs > svg{
    color: var(--bg1);
    font-size: 3.5vmax;

}
}
