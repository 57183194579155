.downNav{
    position: absolute;
    z-index: 99;
    width: 230px;
    top: 4.3vmax;
    margin-left: -5vmax;
    background-color: white;
    /* box-shadow: 0 1px 5px gray; */
    border-radius: 0.05rem;
    display: flex;
    flex-direction: column;
    animation: topNav 0.2s  ease-in-out  ;
}
.downNavHide{
    display: none;
}
.downNav > a{
    padding: 1vmax;
    text-decoration: none;
    color: var(--textC1);
    border-bottom: 1px solid rgb(229, 229, 229);
}
.downNav > a:hover{
background-color: var(--bg2);
color: var(--textC2);
}
.hideSearch {
    transition: all 0.5s;
    margin-top: -8.8vmax;
    position: absolute;
    z-index: -1;
}

.showSearch {
    transition: all 0.5s;

    margin-top: 0vmax;
    position: absolute;
    z-index: 99;
}

.HeaderContainerMain {}

.logoColor {
    color: red;
}

.logoColor2 {
    color: black;
}

.HeaderContainer {
    height: 65px;
    width: 100vw;
    padding: 0rem 7vmax;
    /* padding-left: ; */
    background-color: white;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid rgb(238, 238, 238);
}

.logoBox {
    padding-right: 10vmax;
}

.logoBox>img {
    /* height: 100%; */
    /* mix-blend-mode:darken;     */
    position: absolute;
    top: 0;
    z-index: 999;
    padding: 0.7vmax;
    background-color: white;
}

.linkBox {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
}

.active {
    border-bottom: 3px solid var(--bg1) !important;
}
.classLink,
.linkBox>a {
    display: flex;
    align-items: center;
    height: 100%;
    text-decoration: none;
    font-family: 'Roboto';
    color: var(--textC1);
    padding: 0.5rem 1rem;
    font-size: 15px;
    font-weight: 500;
    text-align: center;
}

.linkBox>a:hover {
    border-bottom: 3px solid var(--bg1);
    /* color: var(--textC); */
}

.iconBox {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: .8rem;
    padding: 1vmax;
}

.iconBox>a:first-child>svg {
    color: var(--bg2);
}

.iconBoxx {
    display: flex;
    align-items: center;
    justify-content: center;
}

.iconBoxx>a,
.iconBox>a {
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    color: var(--textC1);
}

.iconBoxx>a {
    color: var(--textC2) !important;
}

.iconBox>a>svg {
    color: var(--textC1);
    font-size: 1.2vmax;
    transition: all 0.5s;
}

.iconBoxx>a>svg {
    color: var(--textC2);
    font-size: 3vmax;
    transition: all 0.5s;
}

.iconBoxx>a {
    margin: 0 1rem;
}

.iconBoxx>a>svg:hover {
    color: var(--textC1);
    /* font-size: 1.5vmax; */
}

.menu {
    display: flex;
    align-items: center;
    justify-content: center;
    display: none;
}

.menu>svg {
    font-size: 4vmax;
    color: var(--bg1);
}

.menuContainer {
    width: 100vw;
    max-width: 100%;
    height: 50vh;
    position: absolute;
    z-index: 999;
    top: 9 !important;
    background-color: var(--bg2);
    padding: 1rem;
    display: flex;
    flex-direction: column;
    display: flex;
    justify-content: space-around;
    align-items: center;
    transition: all 1s;
    border: 4px solid white;

}

.menuContainer>div:first-child {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    /* justify-content: left; */
    /* align-items: flex-start; */
}

.menuContainer>div:first-child>a {
    width: 100vw !important;
    font-size: 2vmax;
    color: var(--textC2);
    font-weight: 600;
    padding: 0.5rem;
    text-align: left;
    border-bottom: 1px solid gainsboro;
    text-decoration: none;

}

.menuContainer>div>a:hover {
    color: var(--textC1);
}

.menulogoBox {
    width: 100%;
    padding: 1.2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-bottom: 1px solid gray;

}

.menulogoBox>p {
    font-size: 0.7rem;
    text-align: center;
    color: var(--textC1);
    font-weight: 600
}

.menulinkBox {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    margin: 3rem 0;

}

.menulinkBox>div {}

.menulinkBox>div>a {
    width: 100;
    text-decoration: none;
    font-family: Sansita;
    color: var(--textC1);
    /* border: 2px solid black; */
    padding: 0.5rem 0.2rem;
    font-size: 15px;
    transition: 0.5s ease-in-out;
    font-weight: 600;
    text-align: center;
    margin: 10px;
}

.menuiconBox {
    padding: 0.2rem;
    display: flex;
    gap: 1rem;
    border-bottom: 1px solid gray;
    border-top: 1px solid gray;
    padding: 1rem 0;
}

.menulinkBox>div>a:hover {
    color: var(--hoverText);
    padding-left: 0.5rem;
}

.hide {
    top: -100%;
    transition: all 0.4s ease-in-out;
    /* display: none; */
}

.show {
    top: 9vh;
    transition: all 0.4s ease-in-out;
}

.brandName {
    width: 100%;
    text-align: center;
    padding: 0.2rem 0;
    text-align: center;
    color: var(--textC1);
    font-size: 0.8rem;
}

/* search style  */
.searchBox {
    width: 100%;
    padding: 0.8vmax;
    display: flex;
    justify-content: center;
    background-color: var(--bg2);
}

.searchBox>div {
    width: 60%;
    border: 1px solid var(--textC3);
}

.searchBox>div>input:first-child {
    width: 80%;
    border: none;
    outline: none;
    padding: 1vmax;
    padding-left: 1.5vmax;
    cursor: pointer !important;
    font: 300 1vmax 'Roboto';
}

.searchBox>div>input:last-child {
    width: 20%;
    border: none;
    outline: none;
    padding: 1vmax;
    padding-left: 1.5vmax;
    font: 300 1vmax 'Roboto';
    background-color: var(--bg1);
    cursor: pointer;
    color: var(--textC2);
}
.menuLogo{
    display: none;
}

/* search style end */
@media screen and (max-width:952px) {
    .hideSearch {
        /* display: none !important; */
        transition: all 0.5s;
        margin-top: 0vmax;
        position: relative;
        z-index: -1;
    }
    .menuLogo{
        display: block;
    }
    /* .showSearch{
        display: none !important;
        transition: all 0.5s;
    
        margin-top: 0vmax;
        position: relative;
        z-index: -1;
    } */
    .HeaderContainer {
        width: 100vw;
        height: 65px;
        padding: 0.1vmax 0.5vmax;
        justify-content: space-between;

    }

    .logoBox>img {
        width: 150px;
        height: 100%;
        mix-blend-mode: darken !important;
    }

    .menu {
        display: block;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .linkBox,
    .iconBox {
        display: none;
    }

    .brandName {
        width: 100%;
        text-align: center;
        color: var(--textC1);
        font-size: 0.6rem;
    }

    /* search style  */
    .searchBox {
        width: 100%;
        padding: 0.8vmax;
        display: flex;
        justify-content: center;
    }

    .searchBox>div {
        width: 100%;
        border: 1px solid var(--textC3);
    }

    .searchBox>div>input:first-child {
        width: 80%;
        border: none;
        outline: none;
        padding: 1.3vmax;
        padding-left: 1.5vmax;
        font: 300 2vmax 'Roboto';
    }

    .searchBox>div>input:last-child {
        width: 20%;
        border: none;
        outline: none;
        padding: 1.3vmax;
        padding-left: 1.5vmax;
        font: 300 2vmax 'Roboto';
        background-color: var(--bg1);
        cursor: pointer;
        color: var(--textC2);
    }
    .logoBox {
    padding-right: 10vmax;
}
.logoBox {
    padding-right: 0vmax;
}


    /* search style end */
}

@media screen and (min-width:952px) {}