.categoryContainer{
    width: 100%;
    /* padding:  1vmax 5vmax; */
}
.breadcrumbs{
    width: 100%;

    /* position: absolute; */
    padding: 1vmax;
    top: 10vh;
    left: 0;
    /* padding: 2vmax 6vmax; */
    /* border-bottom: 1px solid gray; */
    text-align: center;
}
.breadcrumbs >  span{
    color: var(--textC1);
    font: 300 1.1vmax 'Roboto';
}
.breadcrumbs >  span > a{
    text-decoration: none;
    color: var(--bg1);


}
.breadcrumbs >  span > a:hover{
    /* transition: all 0.5s; */
    border-bottom: 2px solid var(--bg1);
}
.categoryContainer > .headBox{
    width: 100%;
    display: flex;
    justify-content: center;
    text-align: center;
}
.headBox > h2{
    width: 40%;
    /* border-bottom:  2px dashed var(--bg1); */
    padding-bottom: 1vmax ;
    font: 600 2vmax 'Roboto';
    color: var(--textC1);

}

.categoryBox{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    padding: 4vmax 7vmax;
    gap: 2rem;
    justify-content: center;
}
.categoryItem{
    width: 250px;
    /* border: 1px solid var(--bg1); */
    padding: 0vmax;
    padding-top: 1vmax;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 0.2rem;
    gap: 1rem;
    cursor: pointer;
    transition: all 0.4s;
    text-decoration: none;
    /* background-color: var(--bg4); */
}
.all-categoryItem{
    width: 300px;
    /* height: 250px; */
    /* border: 2px solid var(--bg1); */
    /* padding: 2vmax; */
    padding-top: 1.5vmax;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    border-radius: 0.4rem;
    gap: 1rem;
    cursor: pointer;
    transition: all 0.4s;
    background-color: var(--bg4);
    box-shadow: 0 0 20px var(--bg2);

    text-decoration: none;
    overflow: hidden;

}
.imgSection{
    width: 100%;
    height: 40vh;
    overflow: hidden;
    margin-bottom: 2vmax;
    border-radius: 0.4rem;

}
.imgSection > img{
    width: 100%;
}
.all-categoryItem > svg{
    /* width: 100%; */
    /* padding: 2vmax 0; */
    height: 100%;
    color: var(--bg1);
    font-size: 4vmax;
}
.categoryItem:hover,
.all-categoryItem:hover{
    border: 1px solid var(--bg1);
    box-shadow: 0 0 0px var(--bg1);

    > a{
        /* color: var(--textC1); */
    }
}
.categoryItem > a{
    width: 100%;
    text-align: center;
    padding: 1vmax;
    font: 600 1.2vmax 'Roboto';
    text-decoration: none;
    color: var(--bg1);
    /* border-top: 1px solid var(--textC3); */
    /* padding-top: 2vmax; */   
    background-color: var(--bg4);
}
.categoryItem > img{
    mix-blend-mode: darken;
}
.all-categoryItem > a{
    width: 100%;
    background-color: var(--bg2);
    text-align: center;
    padding: 1vmax;
    font: 600 1.2vmax 'Roboto';
    text-decoration: none;
    color: var(--textC2);
    /* padding-top: 2vmax; */
}
.categoryItem > a:hover{
    background-color: var(--bg1);
    /* color: var(--textC2); */
}
.all-categoryItem > a:hover{
    /* color: var(--bg4); */
}

@media screen and (max-width:600px) {
   .categoryContainer{
    width: 100%;
    padding: 0vmax 0vmax;
} 
.breadcrumbs{
    width: 100%;
    /* position: absolute; */
    top: 16.7vh;
    left: 0;
    padding: 0.5vmax 2vmax;
    background-color: var(--bg4);
    /* border-bottom: 1px solid gray; */
}
.breadcrumbs >  span{
    color: var(--textC1);
    font: 300 1.8vmax 'Roboto';
}
.headBox > h2{
    width: 60%;
    /* border-bottom:  2px dashed var(--bg1); */
    padding-bottom: 1vmax ;
    font: 600 2.8vmax 'Roboto';
    /* color: var(--bg1); */

}
.categoryBox{
    
    padding: 1vmax ;
    gap: 0.5rem;
    justify-content: center;
}
.all-categoryItem{
    width: 48%;
    border: 2px solid var(--bg1);
    padding: 3vmax;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 1rem;
    gap: 0.5rem;
    cursor: pointer;
    transition: all 0.4s;
}

.all-categoryItem > a{
    font: 600 2.1vmax 'Roboto';
    text-decoration: none;
    color: var(--textC1);
    /* padding-top: 2vmax; */
}
    
}