.aboutBanner{
    width: 100%;
    height: 40vh;
    background-image: url(https://images.ctfassets.net/3s5io6mnxfqz/3pLESMFjNopcam5J5qGcoT/0427544b060a8fd29e3c0b68281d56cf/AdobeStock_191057762.jpeg);
    background-position: top;
    background-repeat: no-repeat;
    background-size: cover;
    clip-path: ellipse(99% 67% at 49% 26%);

        
}
.img2{
    background-image: url(https://www.elan-chemical.com/wp-content/uploads/2019/01/Synthetic-Aroma-Chemicals-Wide.jpg);
}
.img3{
    background-image: url(https://www.hopkinsmedicine.org/-/media/images/health/3_-wellness/womans-health/variety-of-spices-hero.jpg?h=500&iar=0&mh=500&mw=1300&w=1297&hash=040D9C07D284431866FADA2FF930C2BF);
}
.img4{
    background-image: url(https://static.vecteezy.com/system/resources/previews/002/025/374/non_2x/various-spices-and-herbs-in-wooden-spoons-with-copy-space-free-photo.jpg
    );
}
.aboutBanner > div{
    width: 100%;
    height: 100%;
    color: white;
    text-align: left;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    /* padding: 0vmax 6vmax; */
}
.aboutBanner > div > h1{
    color: var(--textC2) !important;
    padding: 2vmax 4vmax;
    border-radius: 0 4rem  4rem 0;
    background-color: rgba(0, 0, 0, 0.364);
    /* margin: 0 auto; */
    margin-bottom: 2vmax;
}
.aboutBox1{
    display: flex;
    justify-content: center;
    /* align-items: center; */
    /* padding: ; */
}
.aboutBox2 > div,
.aboutBox1 > div{
    width: 50%;
    /* padding-left: 10vmax;/ */
}
/* .aboutBox2 > div:first-child, */
.aboutBox1 > div:first-child{
    padding: 5vmax 0;
    padding-left: 10vmax;
    padding-right: 1vmax;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}
.aboutBox2 > div:first-child > p,
.aboutBox1 > div:first-child > p{
    font: 300 1.1vmax 'Roboto';
    line-height: 24px;
    text-align: justify;
}
.aboutBox2 > div:first-child > h1,
.aboutBox1 > div:first-child > h1{
    font: 600 2vmax 'Roboto';
    color: var(--bg1);
    line-height: 24px;
    text-align: justify;
}

.aboutBox1 > div:last-child{
    padding: 5vmax 0;
    padding-left: 1vmax;
    padding-right: 10vmax !important;
}
.aboutBox1 > div:last-child>img{
 box-shadow: 50px -50px 0px var(--bg1);   
}
.aboutBox1 > div:last-child::before{
    /* content: ""; */
    /* background-image: url(https://makita.in/wp-content/themes/Makita/img/dots.png); */
    /* content: ""; */
    position: absolute;
    bottom: -270px;
    left: 47.5%;
    height: 126px;
    width: 126px;
    background: url(https://makita.in/wp-content/themes/Makita/img/dots.png) no-repeat;
    z-index: -1;
}
.bg > img{
    width: 500px ;
}
.bgBox{
    width: 30%;
    height: 102%;
    /* background-color: var(--bg3); */
    /* background-color: yellowgreen; */
    position: absolute;
    z-index: -1;
    top: 40%;
    right:  0;
}

/* PatnersBox */
.PatnersBox{
    width: 100%;
    padding: 2vmax 10vmax;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-top: 1px solid rgb(225, 225, 225);
    background-color: var(--bg4);
}
.PatnersBox > h2{
    width: 50%;
    font: 600 1.5vmax 'Roboto';
    color: var(--bg1);
    padding: 0.4vmax;
    text-align: center;

    border-bottom: 1px dashed var(--bg3);

}
.PatnersBox > p{
    font: 300 1vmax 'Roboto';
    color: var(--textC1);
    padding: 0.4vmax;
    /* border-bottom: 2px dotted var(--bg3); */
}
.patnerRow{
    display: flex;
    justify-content: space-evenly;
    gap: 2rem;
    padding: 0vmax 0;
}
.patnerItem{
    width: 33%;
    text-align: center;
    padding: 3vmax;
    /* border: 1px solid; */
    /* border: 1px solid rgb(150, 149, 149); */
    /* box-shadow: 1px 1px 20px var(--bg1); */


}
.patnerItem > div{
    width: 240px !important;
    height: 240px !important;
    border: 3px dashed var(--bg1);
    padding: 0.4vmax;
    border-radius: 50%;
    box-shadow: 0px 0px 0px rgb(48, 51, 52);
    overflow: hidden;

}
.patnerItem > div >img{
    /* width: 100%; */
    border-radius: 50%;

}
.patnerItem > img:hover{
    transform: scale(0.9);
    transition: all 0.8s  ;
}
.patnerItem > h3{
    font: 600 1.2vmax 'Roboto';
    color: var(--textC1);
}
.patnerItem > p{
    font: 300 1vmax 'Roboto';
    color: var(--textC1);
    padding-top: 0.5vmax;
}
/* PatnersBox end */

/* aboutBox2 */
.aboutBox2{
    width: 100%;
    padding: 2vmax 10vmax;
    display: flex;
    flex-direction: row-reverse;
    gap: 2rem;
}

.aboutBox2 > div:first-child{
    padding-top: 1.2vmax;
}
.bg2{
    padding: 1vmax;
    /* box-shadow: 2px 2px 10px var(--bg3); */

    /* width: 500px;
    pad

    height: 500px;
    border-radius: 50%;
    overflow: hidden; */
}
.bg2 > img{
    box-shadow: 2px 2px 25px var(--bg3);
    border-radius: 0 2rem 0 2rem;

}
.assContainer{
    padding: 2vmax 5vmax;
    background-color: var(--bg2);

}
.assContainer > h2{
    font: 600 2.2vmax 'roboto';
    text-align: center;
    color: var(--textC2);
}
.associationBox{
    width: 100%;
    padding: 3vmax 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
    gap: 1rem;
    
}
.associationBox >div{
    width: 22%;
    text-align: center;
    padding: 2vmax;

}
.img{
    mix-blend-mode:  normal !important;

}
.associationBox > div > img{
    /* width: 80%; */
    /* height: 40%; */
    /* width: 200px; */
    height: 100px;
    /* background-color: white; */
    /* mix-blend-mode:lighten; */

}
.associationBox > div > p{
    font: 300 1vmax 'Roboto';
    color: var(--textC2);
    padding: 1vmax 0;
}

@media screen and (max-width:600px){
.aboutBanner{
    width: 100%;
    height: 18vh;
    /* background-image: url(https://makita.in/wp-content/uploads/2020/02/about.jpg); */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 0;
        
}
.aboutBanner > div{
    width: 100%;
    height: 100%;
    color: white;
    display: flex;
    /* justify-content: center; */
    align-items: flex-start;
    padding: 1vmax 1vmax;
}
.aboutBox1{
    display: flex;
    justify-content: center;
    flex-direction: column-reverse;
    /* align-items: center; */
    /* padding:0 ; */
}
.aboutBox2 > div,
.aboutBox1 > div{
    width: 100%;
    /* padding-left: 10vmax;/ */
}
.aboutBox2 > div:first-child,
.aboutBox1 > div:first-child{
    padding: 2vmax 0;
    padding-left: 1vmax;
    padding-right: 1vmax;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;}
.aboutBox2 > div:first-child > p,
.aboutBox1 > div:first-child > p{
    font: 300 1.8vmax 'Roboto';
    line-height: 24px;
    text-align: justify;
}
.aboutBox2 > div:first-child > h1,
.aboutBox1 > div:first-child > h1{

    font: 600 2.5vmax 'Roboto';
    color: var(--bg1);
    line-height: 24px;
    text-align: justify;
}
.aboutBox1 > div:last-child{
    padding: 5vmax 0;
    padding-left: 5vmax;
    padding-right: 1vmax !important;
}
.aboutBox1 > div:last-child::before{
    /* content: ""; */
    /* background-image: url(https://makita.in/wp-content/themes/Makita/img/dots.png); */
    /* content: ""; */
    position: absolute;
    bottom: 150px;
    left: 4.5%;
    height: 75px;
    width: 70px;
    background: url(https://makita.in/wp-content/themes/Makita/img/dots.png) no-repeat;
    z-index: -1;
}
.bg > img{
    width: auto !important;
}
/* aboutBox2 */
.aboutBox2{
    width: 100%;
    padding: 5vmax 1vmax;
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

/* PatnersBox */
.PatnersBox{
    width: 100%;
    padding: 2vmax 1vmax;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-top: 1px solid rgb(225, 225, 225);
}
.PatnersBox > h2{
    font: 600 2vmax 'Roboto';
    color: var(--textC1);
}
.PatnersBox > p{
    font: 300 1.5vmax 'Roboto';
    color: var(--textC1);
    padding-top: 0.5vmax;
}
.patnerRow{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    gap: 0rem;
    padding: 3vmax 0;
}
.patnerItem{
    width: 100%;
    text-align: center;
    padding: 2vmax ;
    /* border: 1px solid; */
    /* border: 1px solid rgb(150, 149, 149); */
    /* box-shadow: 1px 1px 20px var(--bg1); */
}
.patnerItem > img{
    width: 80%;
    border: 2px solid var(--textC1);
    padding: 0.2vmax;
    border-radius: 50%;
    box-shadow: 0px 0px 0px rgb(48, 51, 52);
    overflow: hidden;

}
.patnerItem > h3{
    font: 600 1.8vmax 'Roboto';
    color: var(--textC1);
}
.patnerItem > p{
    font: 300 1.6vmax 'Roboto';
    color: var(--textC1);
    padding-top: 0.5vmax;
}
/* PatnersBox end */
.assContainer{
    padding: 2vmax 1vmax;
    background-color: var(--bg2);

}
.assContainer > h2{
    font: 600 3vmax 'roboto';
    text-align: center;
    color: var(--textC2);
}
.associationBox >div{
    width: 100%;
    text-align: center;
    padding: 2vmax;

}
.associationBox > div > img{
    /* width: 80%; */
    /* height: 40%; */
    /* width: 200px; */
    height: 80px;
    /* background-color: white; */
    /* mix-blend-mode:lighten; */

}
.associationBox > div > p{
    font: 300 1.6vmax 'Roboto';
    color: var(--textC2);
    padding: 1vmax 0;
}

}