.infoContainer{
    width: 100vw;
    display: flex;
    padding: 8vmax ;
    gap: 3rem;
    background-color: var(--bg4);
    margin-top: 1px;
    background-image: url('https://novalab.bold-themes.com/nova-b/wp-content/uploads/sites/11/2020/06/background_right_squares.png');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}
.infoBox1{
    width: 50%;
    /* padding-right:  4vmax; */
    display: flex;
    /* padding: 5vmax; */
    /* justify-content: space-evenly; */
    gap: 1.2rem;

    flex-direction: column;
}

.infoBox2{
    width: 50%;
    /* height: 100; */
    /* padding: 0 3vmax; */
    /* padding: 5vmax; */

    display: flex;
    flex-direction: column;
    gap: 2rem;
    /* background-color: var(--bg3); */
}
.infoBox2 > h1{
    font: 600 2vmax 'Roboto';
    padding: 1vmax 0;
    color: var(--textC1);
}

.i2Box{
    width: 100%;
    display: flex;
    gap: 2rem;
}
.i2Box > div{
    width: 40%;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}
.i2Box > div > svg{
    font-size: 3rem;
    color: var(--bg1);
}
.i2Box > div > h2{
    font: 600 1.5vmax 'Roboto';
    color: var(--textC1);
}
.i2Box > div > p{
    font: 300 1vmax 'Roboto';
    color: var(--textC1);
}
/* form Css */
   .infoBox1 > h1{
    font: 600 2vmax 'Roboto';
    padding: 1vmax 0;
    color: var(--textC1);
   }
    .formBox{
        width: 44vw;
        /* background-color: aqua; */
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }
    
    .formBox > div{
        display: flex;
        justify-content: space-between;
        gap: 1rem;
    }
   
    .formBox > div>input{
        width: 100%;
        padding: 0.8vmax  2vmax;
        border: none;
        border: 1px solid rgb(224, 224, 224);
        font: 500 1vmax 'Roboto';
        outline: none;
        color: var(--textC1);
        border-radius: 2rem;

    }
    .textAreaBox{
        display: flex;
        flex-direction: column;
        gap: 0.5rem !important;


    }
    .formBox > div>input:focus,
    .textAreaBox > textarea:focus{
        border: 1px solid var(--bg1);

    }
    .textAreaBox > textarea{
        font-family: 'Roboto';
        color: var(--textC1);
        padding: 1.5vmax;
        font: 500 1vmax 'Roboto';
                border: 1px solid rgb(224, 224, 224);

        outline: none;
        border-radius: 2rem;

    }
    .submitBtn{
        width: 8vmax;
        text-align: center;
        margin-left: 1vmax;
        padding: 0.8vmax 2vmax  ;
        background-color: transparent;
        border: 2px solid var(--bg1);
        border-radius: 2rem;
        font: 600 1vmax 'Roboto';
        color: var(--bg1);
        transition: all 0.5s;
        cursor: pointer;
        box-shadow: 0 0.5px 8px rgb(203, 203, 203);
    }
    .submitBtn:hover{
        background-color: var(--bg1);
        color: var(--textC2);
    }
/* form Css end */
@media screen and  (max-width:800px){
    .infoContainer{
    width: 100vw;
    display: flex;
    flex-direction: column;
    padding: 0.5vmax;
    /* background-color: var(--bg2); */
    gap: 2rem;
}
.infoBox1{
    width: 100%;
    padding:  2vmax;
    display: flex;
    /* justify-content: space-evenly; */
    gap: 1.2rem;

    flex-direction: column;
}

.infoBox2{
    width: 100%;
    padding:  5vmax 2vmax;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    border-top: 1px solid rgb(198, 198, 198);
}
.infoBox2 > h1{
    font: 600 3vmax 'Roboto';
    padding: 1vmax 0;
    color: var(--textC1);
}
.i2Box{
    display: flex;
    gap: 2rem;
}
.i2Box > div > svg{
    font-size: 3rem;
    color: var(--bg1);
}
.i2Box > div > h2{
    font: 600 2vmax 'Roboto';
    color: var(--textC1);
}
.i2Box > div > p{
    font: 300 1.8vmax 'Roboto';
    color: var(--textC1);
}

/* form Css */
.infoBox1 > h1{
    font: 600 3vmax 'Roboto';
    padding: 1vmax 0;
    color: var(--textC1);
    text-align: center;
   }
.formBox{
    width: 100vw;
    /* background-color: aqua; */
    display: flex;
    flex-direction: column;
    gap: 2rem;
}
.formBox > div>input{
    width: 100%;
    padding: 1.4vmax  3vmax;

    font: 500 2vmax 'Roboto';
    outline: none;
    color: var(--textC1);

}
.textAreaBox > textarea{
    font-family: 'Roboto';
    color: var(--textC1);
    padding: 2vmax;
    font: 500 2vmax 'Roboto';
    border: 1px solid rgb(224, 224, 224);

    outline: none;
    border-radius: 2rem;

}
.submitBtn{
    width: 16vmax;
        text-align: center;
        margin: 1vmax auto;
        padding: 1vmax 2vmax  ;
        background-color: transparent;
        border: 2px solid var(--bg1);
        border-radius: 2rem;
        font: 600 2vmax 'Roboto';

}
}