.centerN{
    width: 100%;
    text-align: center !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.centerN > h4{
    color: var(--textC1);
    font: 300 '1.4vmax 'Roboto;
}
.img4{
    background-image: url(https://www.thewowstyle.com/wp-content/uploads/2015/02/now-i-am-free.jpg) !important;
    background-position: center !important;
}
.photosBox{
    width: 100%;
    /* height: 100vh; */
    padding: 1vmax 7vmax ;
    display: flex;
    flex-wrap: wrap;
    gap: 0.3rem;
    text-align: center;
    justify-content: center;
    /* overflow: auto; */

}
.photosBox > img{
    width: 48%;
    /* height: 60%; */

}