*{
    margin: 0;
    padding: 0;
    max-width: 100% !important;

    box-sizing: border-box;
    /* overflow: hidden; */
}
html {
    scroll-behavior: smooth;
}
:root{

    --bg1: #62a93e;
    --bg2: rgb(67, 187, 83);


    --textC1:rgb(56, 56, 56);
    --textC2: rgb(255, 255, 255);

}

@font-face {
    font-family: Roboto;
    src: url(../src/utils/Poppins//Poppins-Regular.ttf);
}
@font-face {
    font-family: roboto;
    src: url(../src/utils/Poppins//Poppins-Regular.ttf);

}