.loading{
    width: 100vw;
    height: 100vh;
    display: grid;
    place-items: center;
    max-width: 100%;
}

.loading > div {
    width: 10vmax;
    height: 10vmax;
    /* background-color: tomato; */
    border-bottom: 4px solid rgb(70, 157, 244);
    border-radius: 50%;
    animation: loadingRotate 800ms linear infinite ;

}

@keyframes loadingRotate {
    to{
        transform: rotateZ(-360deg);
    }
}